import styled from 'styled-components';

export const ContainerLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: #efefef;
  height: 100vh;
`;
