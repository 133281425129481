import React, { useState, useCallback, useEffect } from 'react';

import { Box, DialogProps, Drawer } from '@material-ui/core';
import { WhisperSpinner } from 'react-spinners-kit';

import { SelectBox } from 'devextreme-react/select-box';
import { NumberBox } from 'devextreme-react/number-box';
import CustomStore from 'devextreme/data/custom_store';

import { getYear } from 'date-fns';
import { FormGroup } from '../../../components/FormGroup';
import { Button } from '../../../components/Button';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

import { ContainerLoading } from './styles';

interface DialogCustomProps extends DialogProps {
  handleClose(): void;
  onSubmitted(): void;
  open: boolean;
}

interface CustomStoreProps {
  store: CustomStore;
  paginate: boolean;
}

export const DialogCopyTemplate: React.FC<DialogCustomProps> = ({
  handleClose,
  open,
  onSubmitted,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [fromYear, setFromYear] = useState(getYear(new Date()));
  const [toYear, setToYear] = useState(getYear(new Date()));
  const [typeId, setTypeId] = useState(0);
  const [typesSource, setTypesSource] = useState<CustomStoreProps>(
    {} as CustomStoreProps,
  );

  const { addToast } = useToast();

  const loadTypeSource = useCallback(async () => {
    setTypesSource({
      store: new CustomStore({
        key: 'ID',
        loadMode: 'raw',
        load: async () => {
          const { data } = await api.get(`api/template-types`);

          return data;
        },
      }),
      paginate: true,
    });
  }, []);

  useEffect(() => {
    loadTypeSource();
  }, [loadTypeSource]);

  const handleSubmit = useCallback(async () => {
    if (fromYear && typeId && toYear) {
      setLoading(true);

      try {
        await api.post(`/api/templates/copy`, {
          toYear,
          fromYear,
          idTemplateType: typeId,
        });

        setLoading(false);

        addToast({
          type: 'success',
          title: 'Copied!',
        });
        onSubmitted();
        handleClose();
      } catch {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Something went wrong...',
          description: 'Try again in a few seconds',
        });
      }
    } else {
      addToast({
        type: 'error',
        title: 'Please select years and template type',
      });
    }
  }, [addToast, fromYear, toYear, typeId, onSubmitted, handleClose]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      style={{ position: 'relative' }}
    >
      {loading && (
        <ContainerLoading>
          <WhisperSpinner size={24} backColor="#8b0304" frontColor="#fff" />
          {!!loadingMessage && <p>{loadingMessage}</p>}
        </ContainerLoading>
      )}

      <Box m={2} minWidth={300}>
        <h1 style={{ fontSize: 16, fontWeight: 700 }}>Copy templates</h1>
        <p>Select the years and the type to copy all template</p>
        <p>IMPORTANT: the DOCX files won&apos;t be copied.</p>

        <FormGroup fieldSetLabel="From Year">
          <NumberBox
            stylingMode="outlined"
            onValueChanged={e => setFromYear(e.value)}
            defaultValue={fromYear}
            showSpinButtons
          />
        </FormGroup>

        <FormGroup fieldSetLabel="To Year">
          <NumberBox
            stylingMode="outlined"
            onValueChanged={e => setToYear(e.value)}
            defaultValue={toYear}
            showSpinButtons
          />
        </FormGroup>

        <FormGroup fieldSetLabel="Template type">
          <SelectBox
            stylingMode="outlined"
            dataSource={typesSource}
            searchExpr={['name']}
            minSearchLength={3}
            showDataBeforeSearch
            searchEnabled
            valueExpr="id"
            displayExpr="name"
            onValueChanged={e => setTypeId(e.value)}
            defaultValue={typeId}
          />
        </FormGroup>

        <div
          style={{
            position: 'absolute',
            bottom: 15,
            right: 15,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button onClick={handleClose} style={{ marginRight: 15 }}>
            Cancel
          </Button>

          <Button onClick={handleSubmit} primary>
            Copy
          </Button>
        </div>
      </Box>
    </Drawer>
  );
};
