import React, { useCallback, useState, useEffect } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogProps,
} from '@material-ui/core';

import { TextBox } from 'devextreme-react/text-box';
import { CheckBox } from 'devextreme-react/check-box';

import { WhisperSpinner } from 'react-spinners-kit';

import { Button } from '../../../components/Button';
import { FormGroup } from '../../../components/FormGroup';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

interface DialogCustomProps extends DialogProps {
  fieldId: number;
  handleClose(): void;
  onSubmitted(): void;
}

export const DialogEditField: React.FC<DialogCustomProps> = ({
  fieldId,
  open,
  handleClose,
  onSubmitted,
}) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const [name, setName] = useState('');
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    async function loadField() {
      setLoading(true);

      const { data } = await api.get(`api/fields/${fieldId}`);

      setName(data.name);
      setIsActive(data.isActive);
      setLoading(false);
    }

    loadField();
  }, [fieldId]);

  const handleSubmit = useCallback(async () => {
    if (name) {
      setLoading(true);

      await api.put(`api/fields/${fieldId}`, {
        name,
        isActive,
      });

      addToast({
        title: 'Field updated!',
        type: 'success',
      });
      setLoading(false);
      onSubmitted();
      handleClose();
    }
  }, [onSubmitted, handleClose, addToast, name, isActive, fieldId]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle id="form-dialog-title">Edit field - {name}</DialogTitle>
      <DialogContent>
        {!loading ? (
          <>
            <FormGroup fieldSetLabel="Field name">
              <TextBox
                stylingMode="outlined"
                onValueChanged={e => setName(e.value)}
                placeholder="Ex: Salary average"
                defaultValue={name}
              />
            </FormGroup>
            <FormGroup fieldSetLabel="Is Active?">
              <CheckBox
                onValueChanged={e => setIsActive(e.value)}
                defaultValue={isActive}
              />
            </FormGroup>
          </>
        ) : (
          <WhisperSpinner size={24} backColor="#8b0304" frontColor="#fff" />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} primary loading={loading}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
