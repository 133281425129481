import React, { useEffect, useState, useCallback } from 'react';
import { FiPlus, FiEdit2 } from 'react-icons/fi';
import { WhisperSpinner } from 'react-spinners-kit';

import DataGrid, {
  HeaderFilter,
  Column,
  Scrolling,
} from 'devextreme-react/data-grid';

import { Tooltip, IconButton } from '@material-ui/core';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { BoardBody } from '../../components/BoardBody';
import { BoardHeader } from '../../components/BoardHeader';
import { HeaderComponent } from '../../components/Header';
import { ToolBox, ToolItem } from '../../components/Toolbox';

import { useDocumentTitle } from '../../hooks/documentTitle';

import { Container, ContainerLoading } from './styles';
import { DialogAddField } from './AddField';
import { DialogEditField } from './EditField';
import api from '../../services/api';

export const Field: React.FC<{ title?: string }> = ({ title }) => {
  const [firstLoading, setFirstLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [templatesSource, setTemplatesSource] = useState<DataSource>(
    {} as DataSource,
  );
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [fieldId, setFieldId] = useState(0);

  const { setTitle } = useDocumentTitle();

  useEffect(() => {
    setTitle(title);

    const templatesStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        setLoading(true);

        const { data } = await api.get('/api/fields');

        setLoading(false);
        setFirstLoading(false);
        return data;
      },
    });

    setTemplatesSource(
      new DataSource({
        store: templatesStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [setTitle, title]);

  const boldTextCells = useCallback(
    e => (
      <p
        style={{
          color: '#333333',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
          textAlign: 'center',
        }}
      >
        {e.text}
      </p>
    ),
    [],
  );

  const greyTextCells = useCallback(
    e => (
      <p
        style={{
          color: '#CCCCCC',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
          textAlign: 'center',
        }}
      >
        {e.text}
      </p>
    ),
    [],
  );

  const handleOpenEdit = useCallback(key => {
    setFieldId(key);
    setOpenEdit(true);
  }, []);

  const commandColumnRender = useCallback(
    e => (
      <Tooltip title="Edit field" aria-label="open" placement="left">
        <IconButton
          aria-label="Edit field"
          size="small"
          onClick={() => handleOpenEdit(e.key)}
        >
          <FiEdit2 size={18} />
        </IconButton>
      </Tooltip>
    ),
    [handleOpenEdit],
  );

  return (
    <>
      <Container>
        <HeaderComponent />

        {loading && firstLoading && (
          <ContainerLoading>
            <WhisperSpinner size={50} backColor="#8b0304" />
          </ContainerLoading>
        )}

        <BoardHeader
          title="Template fields"
          subtitle="Template fields management page"
        >
          <ToolBox>
            <ToolItem
              icon={<FiPlus size={16} />}
              title="Add field"
              onClick={() => setOpenAdd(true)}
            />
          </ToolBox>
        </BoardHeader>

        <BoardBody>
          <DataGrid
            showBorders={false}
            showColumnHeaders
            hoverStateEnabled
            dataSource={templatesSource}
          >
            <HeaderFilter visible allowSearch />
            <Scrolling mode="infinite" />

            <Column dataField="id" visible={false} />

            <Column dataField="name" cellRender={boldTextCells} />
            <Column
              dataField="replace"
              caption="Unique"
              cellRender={boldTextCells}
            />
            <Column
              dataField="createdAt"
              cellRender={greyTextCells}
              dataType="date"
              format="d MMM yyyy"
            />
            <Column
              dataField="isActive"
              caption="Is Active?"
              dataType="boolean"
              defaultFilterValues={[true]}
            />
            <Column
              type="buttons"
              cellRender={commandColumnRender}
              width={120}
            />
          </DataGrid>
        </BoardBody>
      </Container>

      {openAdd && (
        <DialogAddField
          handleClose={() => setOpenAdd(false)}
          open={openAdd}
          onSubmitted={() => templatesSource.reload()}
        />
      )}

      {openEdit && (
        <DialogEditField
          fieldId={fieldId}
          handleClose={() => setOpenEdit(false)}
          open={openEdit}
          onSubmitted={() => templatesSource.reload()}
        />
      )}
    </>
  );
};
