import React, { useState, useCallback, useEffect } from 'react';

import { Box, DialogProps, Drawer } from '@material-ui/core';
import { WhisperSpinner } from 'react-spinners-kit';

import { SelectBox } from 'devextreme-react/select-box';
import { NumberBox } from 'devextreme-react/number-box';
import CustomStore from 'devextreme/data/custom_store';

import { getYear } from 'date-fns';
import { FormGroup } from '../../../components/FormGroup';
import { Button } from '../../../components/Button';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

import { ContainerLoading } from './styles';

interface DialogCustomProps extends DialogProps {
  handleClose(): void;
  onSubmitted(): void;
  open: boolean;
}

interface CustomStoreProps {
  store: CustomStore;
  paginate: boolean;
}

export const DialogExport: React.FC<DialogCustomProps> = ({
  handleClose,
  open,
  onSubmitted,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [format, setFormat] = useState<'pdf' | 'word'>('pdf');
  const [year, setYear] = useState(getYear(new Date()));
  const [typeId, setTypeId] = useState(0);
  const [typesSource, setTypesSource] = useState<CustomStoreProps>(
    {} as CustomStoreProps,
  );

  const { addToast } = useToast();

  const loadTypeSource = useCallback(async () => {
    setTypesSource({
      store: new CustomStore({
        key: 'ID',
        loadMode: 'raw',
        load: async () => {
          const { data } = await api.get(`api/template-types`);

          return data;
        },
      }),
      paginate: true,
    });
  }, []);

  useEffect(() => {
    loadTypeSource();
  }, [loadTypeSource]);

  const handleSubmit = useCallback(async () => {
    if (format && typeId) {
      setLoading(true);

      try {
        await api.post(`/api/letters-export/${format}`, {
          year,
          idTemplateType: typeId,
        });

        setLoading(false);

        addToast({
          type: 'info',
          title: 'Your letters will be available soon',
        });

        handleClose();
      } catch {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Something went wrong...',
        });
      }
    } else {
      addToast({
        type: 'error',
        title: 'Please select year and template type',
      });
    }
  }, [addToast, year, typeId, format, handleClose]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      style={{ position: 'relative' }}
    >
      {loading && (
        <ContainerLoading>
          <WhisperSpinner size={24} backColor="#8b0304" frontColor="#fff" />
          {!!loadingMessage && <p>{loadingMessage}</p>}
        </ContainerLoading>
      )}

      <Box m={2} minWidth={300}>
        <h1 style={{ fontSize: 16, fontWeight: 700 }}>Generate letters</h1>
        <p>Select the format and generate the letters</p>

        <FormGroup fieldSetLabel="Format">
          <SelectBox
            stylingMode="outlined"
            dataSource={['word', 'pdf']}
            onValueChanged={e => setFormat(e.value)}
            defaultValue={format}
          />
        </FormGroup>

        <FormGroup fieldSetLabel="Reference Year">
          <NumberBox
            stylingMode="outlined"
            onValueChanged={e => setYear(e.value)}
            defaultValue={year}
            showSpinButtons
          />
        </FormGroup>

        <FormGroup fieldSetLabel="Template type">
          <SelectBox
            stylingMode="outlined"
            dataSource={typesSource}
            searchExpr={['name']}
            minSearchLength={3}
            showDataBeforeSearch
            searchEnabled
            valueExpr="id"
            displayExpr="name"
            onValueChanged={e => setTypeId(e.value)}
            defaultValue={typeId}
          />
        </FormGroup>

        <div
          style={{
            position: 'absolute',
            bottom: 15,
            right: 15,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button onClick={handleClose} style={{ marginRight: 15 }}>
            Cancel
          </Button>

          <Button onClick={handleSubmit} primary>
            Generate
          </Button>
        </div>
      </Box>
    </Drawer>
  );
};
