import React, { useState, useCallback, useEffect } from 'react';

import { Box, DialogProps, Drawer } from '@material-ui/core';
import { WhisperSpinner } from 'react-spinners-kit';

import { SelectBox } from 'devextreme-react/select-box';
import CustomStore from 'devextreme/data/custom_store';

import { FormGroup } from '../../../components/FormGroup';
import { Button } from '../../../components/Button';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

import { ContainerLoading } from './styles';

interface DialogCustomProps extends DialogProps {
  handleClose(): void;
  onSubmitted(): void;
  open: boolean;
  letterIds: number[];
}

interface CustomStoreProps {
  store: CustomStore;
  paginate: boolean;
}

export const DialogChangeTemplate: React.FC<DialogCustomProps> = ({
  handleClose,
  open,
  onSubmitted,
  letterIds,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [templateId, setTemplateId] = useState(0);
  const [templatesSource, setTemplatesSource] = useState<CustomStoreProps>(
    {} as CustomStoreProps,
  );
  const { addToast } = useToast();

  const loadTemplateSource = useCallback(async () => {
    setTemplatesSource({
      store: new CustomStore({
        key: 'ID',
        loadMode: 'raw',
        load: async () => {
          const { data } = await api.get(`api/templates`);

          return data;
        },
      }),
      paginate: true,
    });
  }, []);

  useEffect(() => {
    loadTemplateSource();
  }, [loadTemplateSource]);

  const handleSubmit = useCallback(async () => {
    if (templateId) {
      setLoading(true);

      try {
        await api.patch('/api/letters/change-template', {
          idTemplate: templateId,
          ids: letterIds,
        });

        setLoading(false);
        handleClose();
        onSubmitted();
      } catch {
        addToast({
          type: 'error',
          title: 'Please select a template',
        });
      }
    }
  }, [templateId, letterIds, addToast, handleClose, onSubmitted]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      style={{ position: 'relative' }}
    >
      {loading && (
        <ContainerLoading>
          <WhisperSpinner size={24} backColor="#8b0304" frontColor="#fff" />
          {!!loadingMessage && <p>{loadingMessage}</p>}
        </ContainerLoading>
      )}

      <Box m={2} minWidth={300}>
        <h1 style={{ fontSize: 16, fontWeight: 700 }}>Change template bulk</h1>
        <p>You about to change template of selected letters</p>

        <FormGroup fieldSetLabel="Templates">
          <SelectBox
            stylingMode="outlined"
            dataSource={templatesSource}
            searchExpr={['name']}
            minSearchLength={3}
            showDataBeforeSearch
            searchEnabled
            valueExpr="id"
            displayExpr="name"
            onValueChanged={e => setTemplateId(e.value)}
            defaultValue={templateId}
          />
        </FormGroup>

        <div
          style={{
            position: 'absolute',
            bottom: 15,
            right: 15,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button onClick={handleClose} style={{ marginRight: 15 }}>
            Cancel
          </Button>

          <Button onClick={handleSubmit} primary>
            Apply
          </Button>
        </div>
      </Box>
    </Drawer>
  );
};
