import React, { useEffect, useState, useCallback } from 'react';
import { FiPlus, FiEdit2, FiCopy } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { WhisperSpinner } from 'react-spinners-kit';

import DataGrid, {
  HeaderFilter,
  Column,
  Scrolling,
} from 'devextreme-react/data-grid';

import { Tooltip, IconButton } from '@material-ui/core';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { BoardBody } from '../../components/BoardBody';
import { BoardHeader } from '../../components/BoardHeader';
import { HeaderComponent } from '../../components/Header';
import { ToolBox, ToolItem } from '../../components/Toolbox';

import { useDocumentTitle } from '../../hooks/documentTitle';

import { Container, ContainerLoading } from './styles';
import { DialogAddTemplate } from './AddTemplate';
import { DialogCopyTemplate } from './CopyTemplate';
import api from '../../services/api';

export const Template: React.FC<{ title?: string }> = ({ title }) => {
  const [firstLoading, setFirstLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [templatesSource, setTemplatesSource] = useState<DataSource>(
    {} as DataSource,
  );
  const [openAdd, setOpenAdd] = useState(false);
  const [openCopy, setOpenCopy] = useState(false);

  const { setTitle } = useDocumentTitle();

  useEffect(() => {
    setTitle(title);

    const templatesStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        setLoading(true);

        const { data } = await api.get('/api/templates');

        setLoading(false);
        setFirstLoading(false);

        return data;
      },
    });

    setTemplatesSource(
      new DataSource({
        store: templatesStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [setTitle, title]);

  const boldTextCells = useCallback(
    e => (
      <p
        style={{
          color: '#333333',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
          textAlign: 'center',
        }}
      >
        {e.text}
      </p>
    ),
    [],
  );

  const greyTextCells = useCallback(
    e => (
      <p
        style={{
          color: '#CCCCCC',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
          textAlign: 'center',
        }}
      >
        {e.text}
      </p>
    ),
    [],
  );

  const commandColumnRender = useCallback(
    e => (
      <Tooltip title="Edit template" aria-label="open" placement="left">
        <Link to={`/templates/${e.key}`}>
          <IconButton aria-label="Edit template" size="small">
            <FiEdit2 size={18} />
          </IconButton>
        </Link>
      </Tooltip>
    ),
    [],
  );

  return (
    <>
      <Container>
        <HeaderComponent />

        {loading && firstLoading && (
          <ContainerLoading>
            <WhisperSpinner size={50} backColor="#8b0304" />
          </ContainerLoading>
        )}

        <BoardHeader title="Templates" subtitle="Template management page">
          <ToolBox>
            <ToolItem
              icon={<FiPlus size={16} />}
              title="Add template"
              onClick={() => setOpenAdd(true)}
            />
            <ToolItem
              icon={<FiCopy size={16} />}
              title="Copy templates"
              onClick={() => setOpenCopy(true)}
            />
          </ToolBox>
        </BoardHeader>

        <BoardBody>
          <DataGrid
            showBorders={false}
            showColumnHeaders
            dataSource={templatesSource}
          >
            <HeaderFilter visible allowSearch />
            <Scrolling mode="infinite" />

            <Column
              dataField="id"
              cellRender={boldTextCells}
              alignment="left"
              width={100}
            />

            <Column dataField="name" cellRender={boldTextCells} />
            <Column
              dataField="year"
              caption="Reference year"
              cellRender={boldTextCells}
            />
            <Column
              dataField="templateType.name"
              caption="Type"
              cellRender={boldTextCells}
            />
            <Column
              dataField="createdAt"
              cellRender={greyTextCells}
              dataType="date"
              format="d MMM yyyy"
            />
            <Column
              type="buttons"
              cellRender={commandColumnRender}
              width={120}
            />
          </DataGrid>
        </BoardBody>
      </Container>

      {openAdd && (
        <DialogAddTemplate
          handleClose={() => setOpenAdd(false)}
          open={openAdd}
          onSubmitted={() => templatesSource.reload()}
        />
      )}

      {openCopy && (
        <DialogCopyTemplate
          handleClose={() => setOpenCopy(false)}
          open={openCopy}
          onSubmitted={() => templatesSource.reload()}
        />
      )}
    </>
  );
};
