import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  background-color: #ffffff;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  width: 80px;
`;

export const Control = styled.div`
  width: 100%;
  height: 100%;
  -ms-flex-negative: 0;
  flex-shrink: 0;
`;

export const ControlComponent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  border-left: 10px solid #24823f;
  border-right: 1px solid #e1e1e1;
`;

export const CompanyLogoComponentWrapper = styled.div`
  margin: 12px 0px 14px;
  display: flex;
  justify-content: center;
`;

export const NavigationItemsArea = styled.div`
  flex-grow: 1;
`;

export const ActionIconWrapper = styled.div`
  height: 100%;
  margin-bottom: 8px;
  margin-top: 32px;
  display: flex;
  justify-content: center;
`;

export const ViewIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
`;

export const NotificationsComponent = styled.div`
  display: flex;
  justify-content: center;
`;

export const NavigationDialogItemComponent = styled.div`
  height: 44px;
  width: 44px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 100ms ease;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.6);
  }

  svg {
    font-size: 20px;
    color: #ffffff;
  }
`;

export const SubControl = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 80px;
  min-width: 150px;
  background-color: #fff;
  z-index: 1001;
  padding: 32px 0;
  box-shadow: 0px 8px 10px -5px rgb(0 0 0 / 20%),
    0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%);

  h2 {
    font-size: 18px;
    padding: 0 16px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  ul {
    list-style: none;

    a {
      color: #333;
    }

    a:hover {
      text-decoration: none;
    }

    li {
      cursor: pointer;
      padding: 8px 16px;
    }

    li:hover {
      background-color: rgba(0, 0, 0, 0.04);
      text-decoration: none;
    }
  }
`;
