import React, { useEffect, useState, useCallback } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { AiOutlineFilePdf, AiOutlineFileWord } from 'react-icons/ai';

import DataGrid, {
  HeaderFilter,
  Column,
  Scrolling,
  Selection,
} from 'devextreme-react/data-grid';

import { WhisperSpinner } from 'react-spinners-kit';

import { Tooltip, IconButton } from '@material-ui/core';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { BoardBody } from '../../components/BoardBody';
import { BoardHeader } from '../../components/BoardHeader';
import { HeaderComponent } from '../../components/Header';
import { ToolBox, ToolItem } from '../../components/Toolbox';
import { ProfessionalLookup } from '../../components/ProfessionalLookup';

import { useDocumentTitle } from '../../hooks/documentTitle';
import { useToast } from '../../hooks/toast';

import { Container, ContainerLoading } from './styles';
import { DialogAddLetter } from './AddLetter';
import { DialogEditLetter } from './EditLetter';
import { DialogImport } from './Import';
import { DialogExport } from './Export';
import { DialogFiles } from './Files';
import { DialogChangeTemplate } from './ChangeTemplate';
import api from '../../services/api';

export const Letter: React.FC<{ title?: string }> = ({ title }) => {
  const [firstLoading, setFirstLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [letterSource, setLetterSource] = useState<DataSource>(
    {} as DataSource,
  );
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [openFiles, setOpenFiles] = useState(false);
  const [openChangeTemplate, setOpenChangeTemplate] = useState(false);
  const [letterId, setLetterId] = useState(0);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const { addToast } = useToast();

  const { setTitle } = useDocumentTitle();

  useEffect(() => {
    setTitle(title);

    const letterStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        setLoading(true);

        const { data } = await api.get('/api/letters');

        setLoading(false);
        setFirstLoading(false);

        return data;
      },
    });

    setLetterSource(
      new DataSource({
        store: letterStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [setTitle, title]);

  const boldTextCells = useCallback(
    e => (
      <p
        style={{
          color: '#333333',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
          textAlign: 'right',
        }}
      >
        {e.text}
      </p>
    ),
    [],
  );

  const greyTextCells = useCallback(
    e => (
      <p
        style={{
          color: '#CCCCCC',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
        }}
      >
        {e.text}
      </p>
    ),
    [],
  );

  const handleOpenEdit = useCallback(key => {
    setLetterId(key);
    setOpenEdit(true);
  }, []);

  const handleSingleExport = useCallback(
    async (id, format) => {
      try {
        addToast({
          type: 'info',
          title: 'Your files will be available soon',
        });

        await api.post(`/api/letters-export/${format}/selected`, {
          ids: [id],
        });
      } catch {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Something went wrong...',
          description: 'Try again in a few seconds',
        });
      }
    },
    [addToast],
  );

  const commandColumnRender = useCallback(
    e => (
      <>
        <Tooltip title="Edit letter" aria-label="open" placement="left">
          <IconButton
            aria-label="Edit letter"
            size="small"
            onClick={() => handleOpenEdit(e.key)}
          >
            <FiEdit2 size={18} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Export to PDF" aria-label="open" placement="left">
          <IconButton
            aria-label="Export"
            size="small"
            onClick={() => handleSingleExport(e.key, 'pdf')}
          >
            <AiOutlineFilePdf size={20} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Export to Word" aria-label="open" placement="left">
          <IconButton
            aria-label="Export"
            size="small"
            onClick={() => handleSingleExport(e.key, 'word')}
          >
            <AiOutlineFileWord size={20} />
          </IconButton>
        </Tooltip>
      </>
    ),
    [handleOpenEdit, handleSingleExport],
  );

  const professionalCell = useCallback(
    e => (
      <ProfessionalLookup
        name={e.data.professional && e.data.professional.name}
        login={e.data.professional && e.data.professional.login}
        jobtitle={
          e.data.professional.jobTitle && e.data.professional.jobTitle.name
        }
        style={{ cursor: 'pointer' }}
      />
    ),
    [],
  );

  const handleSelectionChanged = useCallback(({ selectedRowKeys }) => {
    setSelectedIds(selectedRowKeys);
  }, []);

  const handleExportPDFSelected = useCallback(async () => {
    if (selectedIds.length > 0) {
      try {
        addToast({
          type: 'info',
          title: 'Your files will be available soon',
        });

        await api.post(`/api/letters-export/pdf/selected`, {
          ids: selectedIds,
        });
      } catch {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Something went wrong...',
          description: 'Try again in a few seconds',
        });
      }
    }
  }, [selectedIds, addToast]);

  return (
    <>
      <Container>
        <HeaderComponent />

        {loading && firstLoading && (
          <ContainerLoading>
            <WhisperSpinner size={50} backColor="#8b0304" />
          </ContainerLoading>
        )}

        <BoardHeader title="Letter" subtitle="Letter management page">
          <ToolBox>
            {selectedIds.length > 0 && (
              <>
                <ToolItem
                  title="Change template"
                  onClick={() => setOpenChangeTemplate(true)}
                />
                <ToolItem
                  title="Export selected"
                  onClick={handleExportPDFSelected}
                />
              </>
            )}
            <ToolItem
              title="Export letters"
              primary
              onClick={() => setOpenExport(true)}
            />
            <ToolItem
              title="Import Data"
              secondary
              onClick={() => setOpenImport(true)}
            />
            <ToolItem
              title="Your files"
              secondary
              onClick={() => setOpenFiles(true)}
            />
            <ToolItem title="Add Letter" onClick={() => setOpenAdd(true)} />
          </ToolBox>
        </BoardHeader>

        <BoardBody>
          <DataGrid
            showBorders={false}
            showColumnHeaders
            hoverStateEnabled
            dataSource={letterSource}
            onSelectionChanged={handleSelectionChanged}
          >
            <Selection
              mode="multiple"
              selectAllMode="allPages"
              showCheckBoxesMode="onClick"
            />
            <HeaderFilter visible allowSearch />
            <Scrolling mode="infinite" />

            <Column dataField="id" visible={false} />

            <Column
              dataField="professional.name"
              caption="Professional"
              cellRender={professionalCell}
              allowGrouping={false}
            />
            <Column
              dataField="template.name"
              caption="Template"
              cellRender={greyTextCells}
            />
            <Column
              dataField="templateType.name"
              caption="Type"
              cellRender={greyTextCells}
            />
            <Column
              dataField="year"
              caption="Reference Year"
              cellRender={boldTextCells}
            />
            <Column
              dataField="isActive"
              caption="Is Active?"
              dataType="boolean"
              defaultFilterValues={[true]}
            />
            <Column
              type="buttons"
              cellRender={commandColumnRender}
              width={120}
            />
          </DataGrid>
        </BoardBody>
      </Container>

      {openAdd && (
        <DialogAddLetter
          handleClose={() => setOpenAdd(false)}
          open={openAdd}
          onSubmitted={() => letterSource.reload()}
        />
      )}

      {openEdit && (
        <DialogEditLetter
          letterId={letterId}
          handleClose={() => setOpenEdit(false)}
          open={openEdit}
          onSubmitted={() => letterSource.reload()}
        />
      )}

      {openImport && (
        <DialogImport
          handleClose={() => setOpenImport(false)}
          open={openImport}
          onSubmitted={() => letterSource.reload()}
        />
      )}

      {openExport && (
        <DialogExport
          handleClose={() => setOpenExport(false)}
          open={openExport}
          onSubmitted={() => letterSource.reload()}
        />
      )}

      {openChangeTemplate && (
        <DialogChangeTemplate
          handleClose={() => setOpenChangeTemplate(false)}
          open={openChangeTemplate}
          onSubmitted={() => letterSource.reload()}
          letterIds={selectedIds}
        />
      )}

      {openFiles && (
        <DialogFiles handleClose={() => setOpenFiles(false)} open={openFiles} />
      )}
    </>
  );
};
