import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;

  header {
    display: flex;
    justify-content: space-between;
    padding: 0 35px;

    span {
      display: flex;
    }

    div {
      display: flex;

      h1 {
        font-size: 20px;
        color: #aaaaaa;
        font-weight: 700;
        letter-spacing: 0.1em;
        text-transform: uppercase;
      }

      .requiredArea {
        padding-left: 15px;
        align-items: center;

        p {
          font-weight: 700;
          letter-spacing: 0.1em;
        }
      }
    }

    .updateArea {
      align-items: center;

      p {
        padding: 0 3px;
        font-weight: 500;
        letter-spacing: 0.1em;
      }
    }
  }

  hr {
    border-top: 0;
    border-left: 0;
    border-bottom: 2px solid #dddddd;
  }

  .row {
    width: 99%;
    display: flex;
    justify-content: space-evenly;
    padding: 10px 0;
    padding-left: 5px;

    .col {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 25px;
    }
  }
`;

export const Section = styled.section`
  margin-bottom: 50px;
  width: 100%;
  h1 {
    padding: 0 35px;
    font-size: 20px;
    color: #aaaaaa;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  .addAdjustment {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 20px;
  }

  .dx-htmleditor {
    box-shadow: none !important;
  }

  .dx-htmleditor-content {
    background-color: #fff;
  }

  .dx-htmleditor .dx-htmleditor-toolbar-wrapper:first-child {
    border-bottom: 0 !important;
  }
`;

export const SectionTitle = styled.div`
  padding: 0 30px;
  padding-bottom: 5px;
  font-size: 20px;
  color: #aaaaaa;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 10px;
  border-bottom: 2px solid #dddddd;

  p {
    font-size: 14px;
    color: #333;
  }
`;

export const ContainerLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: #efefef;
  height: 100vh;
`;
