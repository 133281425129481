import React from 'react';

import {
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core';

import { FiFile } from 'react-icons/fi';

interface FileProps {
  name: string;
}

interface FileListProps {
  files: FileProps[];
}

export const FileList: React.FC<FileListProps> = ({ files }) => {
  return (
    <>
      <Typography variant="h6">Files:</Typography>
      <List>
        {files.map(file => (
          <ListItem
            key={file.name}
            style={{ backgroundColor: '#f5f5f5', borderRadius: 8 }}
          >
            <ListItemAvatar>
              <Avatar style={{ width: 30, height: 30 }}>
                <FiFile size={15} />
              </Avatar>
            </ListItemAvatar>
            <Typography variant="body2" style={{ maxWidth: 200 }}>
              {file.name}
            </Typography>
          </ListItem>
        ))}
      </List>
    </>
  );
};
