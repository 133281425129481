import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';

import { config } from '../config/ms-auth';

import api, { ISignIn } from '../services/master';
import { Permissions } from '../services/api';

interface UserData {
  userName: string;
  userEmail: string;
  scopes: string[];
  comanagementId: number | '';
  login: string;
  userId: number;
  practiceId: number | '';
}

interface AuthState {
  token: string;
  user: UserData;
}

interface AuthContextData {
  user: UserData;
  signInAuthorize(accessToken: string): Promise<void>;
  signOut(): void;
  redirectMicrosoft(): void;
  isAuthenticated: boolean;
  hasScopes(scopes: string[]): boolean;
}

interface IPermissions {
  [key: string]: any;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [data] = useState<AuthState>(() => {
    const token = localStorage.getItem('accessToken') as string;
    const userName = localStorage.getItem('userName') as string;
    const userEmail = localStorage.getItem('userEmail') as string;
    const scopes = localStorage.getItem('scopes') as string;
    const comanagementId = localStorage.getItem('comanagementId') as string;
    const userId = localStorage.getItem('userId') as string;
    const practiceId = localStorage.getItem('practiceId') as string;
    const login = userEmail && (userEmail.split('@')[0] as string);

    if (token && userName && userEmail && scopes && comanagementId) {
      return {
        token,
        user: {
          userName,
          userEmail,
          scopes: JSON.parse(scopes),
          comanagementId: comanagementId && parseInt(comanagementId, 10),
          login,
          userId: parseInt(userId, 10),
          practiceId: practiceId && parseInt(practiceId, 10),
        },
      };
    }

    return {} as AuthState;
  });

  const handleSignInMicrosoft = useCallback(() => {
    const baseUri = `https://login.microsoftonline.com/${config.tenantId}/oauth2/v2.0`;

    const uriPieces = {
      client_id: config.clientId as string,
      response_type: 'token',
      redirect_uri: config.redirectUri as string,
      scope: config.scopes.toString(),
      response_mode: 'fragment',
      nonce: config.nonce as string,
      prompt: 'select_account',
    };

    const params = new URLSearchParams(uriPieces).toString();
    const url = `${baseUri}/authorize?${params}`;

    window.location.href = url;
  }, []);

  const handleSignIn = useCallback(async (accessToken: string) => {
    try {
      const response = await api.post<ISignIn>(
        '/auth/signin',
        {
          scopes: ['letter'],
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );

      const permissions = Permissions as IPermissions;
      const hasScopes = [] as string[];
      Object.keys(permissions as IPermissions).forEach(key => {
        if (response.data.scopes.includes(permissions[key])) {
          hasScopes.push(key);
        }
      });

      if (hasScopes.length <= 0) throw new Error('No scopes');

      localStorage.setItem(
        'accessToken',
        `Bearer ${response.data.accessToken}`,
      );
      localStorage.setItem(
        'userEmail',
        response.data.email || response.data.userPrincipalName,
      );
      localStorage.setItem('userName', response.data.displayName);
      localStorage.setItem('scopes', JSON.stringify(response.data.scopes));
      localStorage.setItem('comanagementId', response.data.comanagementId);
      localStorage.setItem('practiceId', response.data.practiceId);
      localStorage.setItem('userId', response.data.professionalId);
    } catch (err) {
      throw new Error('Error on sign in');
    }
  }, []);

  const handleSignOut = useCallback(() => {
    localStorage.clear();

    const baseUri = `https://login.microsoftonline.com/${config.tenantId}/oauth2/v2.0`;
    const uriPieces = {
      post_logout_redirect_uri: config.redirectUriSignout as string,
    };

    const params = new URLSearchParams(uriPieces).toString();
    window.location.href = `${baseUri}/logout?${params}`;
  }, []);

  const isAuthenticated = useMemo(() => {
    const accessToken = localStorage.getItem('accessToken');

    return !!accessToken;
  }, []);

  const hasScopes = useCallback(
    scopes => {
      return data.user && data.user.scopes.some(x => scopes.includes(x));
    },
    [data.user],
  );

  const providerValue = useMemo(
    () => ({
      user: data.user,
      redirectMicrosoft: handleSignInMicrosoft,
      signInAuthorize: handleSignIn,
      signOut: handleSignOut,
      isAuthenticated,
      hasScopes,
    }),
    [
      data,
      handleSignInMicrosoft,
      handleSignIn,
      handleSignOut,
      hasScopes,
      isAuthenticated,
    ],
  );

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
