import React, { useCallback, useEffect, useState } from 'react';
import { Box, DialogProps, Drawer } from '@material-ui/core';
import CustomStore from 'devextreme/data/custom_store';
import { SelectBox } from 'devextreme-react/select-box';
import { NumberBox } from 'devextreme-react/number-box';

import { WhisperSpinner } from 'react-spinners-kit';
import { getYear } from 'date-fns';
import { FileList } from './FileList';
import { Upload } from '../../../components/Upload';
import { FormGroup } from '../../../components/FormGroup';
import { Alert } from '../../../components/Alert';
import { Button } from '../../../components/Button';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

import { ContainerLoading } from './styles';

interface DialogCustomProps extends DialogProps {
  handleClose(): void;
  onSubmitted(): void;
  open: boolean;
}

interface FileProps {
  file: File;
  name: string;
  readableSize: number;
}

interface ImportResponseProps {
  success: boolean;
  errors: string[];
}

interface CustomStoreProps {
  store: CustomStore;
  paginate: boolean;
}

export const DialogImport: React.FC<DialogCustomProps> = ({
  handleClose,
  open,
  onSubmitted,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<FileProps[]>([]);
  const [filesRaw, setFilesRaw] = useState<File[]>([]);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(getYear(new Date()));
  const [typeId, setTypeId] = useState(0);
  const [errors, setErrors] = useState<string[]>([]);
  const [typesSource, setTypesSource] = useState<CustomStoreProps>(
    {} as CustomStoreProps,
  );
  const { addToast } = useToast();

  const loadTypeSource = useCallback(async () => {
    setTypesSource({
      store: new CustomStore({
        key: 'ID',
        loadMode: 'raw',
        load: async () => {
          const { data } = await api.get(`api/template-types`);

          return data;
        },
      }),
      paginate: true,
    });
  }, []);

  useEffect(() => {
    loadTypeSource();
  }, [loadTypeSource]);

  const submitFile = useCallback((files: File[]) => {
    const filesProps = files.map(file => ({
      file,
      name: file.name,
      readableSize: file.size,
    }));

    setFilesRaw(files);

    setUploadedFiles(filesProps);
  }, []);

  const handleDownloadTemplate = useCallback(async () => {
    if (year && typeId) {
      setLoading(true);
      const response = await api.get(`/api/letters-import/template`, {
        responseType: 'blob',
        params: {
          year,
          idTemplateType: typeId,
        },
      });

      setLoading(false);

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `letters-excel.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      addToast({
        type: 'error',
        title: 'Please select year and template type',
      });
    }
  }, [year, typeId, addToast]);

  const handleSubmit = useCallback(async () => {
    if (year && typeId) {
      setLoading(true);
      const data = new FormData();
      filesRaw.map(fileRaw => {
        data.append('file', fileRaw, fileRaw.name);
      });
      data.append('year', year?.toString());
      data.append('idTemplateType', typeId?.toString());

      try {
        const response = await api.post<ImportResponseProps>(
          `/api/letters-import`,
          data,
        );

        setLoading(false);
        if (!response.data.success) {
          setErrors(response.data.errors);
          addToast({
            type: 'info',
            title: 'Data imported with some errors...',
          });
          onSubmitted();
        } else {
          onSubmitted();
          handleClose();
          addToast({
            type: 'success',
            title: 'Data imported successfully',
          });
        }
      } catch {
        setLoading(false);
      }
    } else {
      addToast({
        type: 'error',
        title: 'Please select year and template type',
      });
    }
  }, [filesRaw, year, typeId, addToast, onSubmitted, handleClose]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      style={{ position: 'relative' }}
    >
      {loading && (
        <ContainerLoading>
          <WhisperSpinner size={24} backColor="#8b0304" frontColor="#fff" />
          {!!loadingMessage && <p>{loadingMessage}</p>}
        </ContainerLoading>
      )}

      <Box m={2} minWidth={300}>
        <h1 style={{ fontSize: 16, fontWeight: 700 }}>Import CSV</h1>
        <p>To import your file please, follow the excel below</p>
        {errors.length > 0 && (
          <Alert type="warning" style={{ marginBottom: 15 }}>
            <h1>Imported with some warning</h1>
            <ul style={{ marginLeft: 15 }}>
              {errors.map(error => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </Alert>
        )}
        <FormGroup fieldSetLabel="Template type">
          <SelectBox
            stylingMode="outlined"
            dataSource={typesSource}
            searchExpr={['name']}
            minSearchLength={3}
            showDataBeforeSearch
            searchEnabled
            valueExpr="id"
            displayExpr="name"
            onValueChanged={e => setTypeId(e.value)}
            defaultValue={typeId}
          />
        </FormGroup>
        <FormGroup fieldSetLabel="Reference Year">
          <NumberBox
            stylingMode="outlined"
            onValueChanged={e => setYear(e.value)}
            defaultValue={year}
            showSpinButtons
          />
        </FormGroup>

        <div style={{ marginBottom: 15 }}>
          <Upload onUpload={submitFile} />
        </div>

        {!!uploadedFiles.length && <FileList files={uploadedFiles} />}

        <div
          style={{
            position: 'absolute',
            bottom: 15,
            right: 15,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button style={{ marginRight: 15 }} onClick={handleDownloadTemplate}>
            Download excel
          </Button>

          <Button onClick={handleSubmit} primary>
            Import
          </Button>
        </div>
      </Box>
    </Drawer>
  );
};
