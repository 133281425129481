import React, { useCallback } from 'react';

import { Header, HeaderAvatar, HeaderLogo } from './styles';

import SalesLogo from '../../assets/images/sales-cell-logo.png';

import { useAuth } from '../../hooks/auth';

export const HeaderComponent: React.FC = () => {
  const { user } = useAuth();

  const addBreakline = useCallback((s: string) => {
    return s.split(' ').map(item => (
      <span key={item}>
        {item}
        <br />
      </span>
    ));
  }, []);

  return (
    <Header>
      <HeaderAvatar>
        <img
          src={`https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${user.login}_192_192.png`}
          alt={user.userName}
        />
        <div>
          <p className="welcome">Welcome</p>
          <p className="user-name">
            {user.userName && addBreakline(user.userName)}
          </p>
        </div>
      </HeaderAvatar>

      {/* <HeaderLogo>
        <img src={SalesLogo} className="salesLogo" alt="Sales cell logo" />
      </HeaderLogo> */}
    </Header>
  );
};
