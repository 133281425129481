import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { WhisperSpinner } from 'react-spinners-kit';
import CustomStore from 'devextreme/data/custom_store';

import { Container, ContainerLoading, Section } from './styles';

import { HeaderComponent } from '../../../components/Header';
import { FormGroup } from '../../../components/FormGroup';
import { SelectBox } from '../../../components/SelectBox';
import { TextBox } from '../../../components/TextBox';
import { Switch } from '../../../components/Switch';
import { BoardBody } from '../../../components/BoardBody';
import { TemplateFileManager } from '../../../components/TemplateFileManager';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import master from '../../../services/master';

interface CustomStoreProps {
  store: CustomStore;
  paginate: boolean;
}

interface FieldProps {
  id: number;
  name: string;
}

interface TemplateTypeFieldsProps {
  id: number;
  field: FieldProps;
}

interface TemplateTypeProps {
  id: number;
  name: string;
  templateTypeFields: TemplateTypeFieldsProps[];
}

interface TemplateProps {
  name: string;
  idTemplateType: number;
  year: number;
  idProfessional: number | null;
  idJobTitle: number | null;
  idArea: number | null;
  idIntegrationGroup: number | null;
  idOffice: number | null;
  idPractice: number | null;
  text: string;
  templateType: TemplateTypeProps;
  isFromDocx: boolean;
  docxReference?: string;
}

export const EditTemplate: React.FC = () => {
  const { id: templateId } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [professionalId, setProfessionalId] = useState(0);
  const [jobtitleId, setJobtitleId] = useState(0);
  const [areaId, setAreaId] = useState(0);
  const [isFromDocx, setIsFromDocx] = useState(false);
  const [docxReference, setDocxReference] = useState('');
  const [integrationGroupId, setIntegrationGroupId] = useState(0);
  const [year, setYear] = useState(0);
  const [templateTypeId, setTemplateTypeId] = useState(0);
  const [officeId, setOfficeId] = useState(0);
  const [practiceId, setPracticeId] = useState(0);

  const [areaStore, setAreaStore] = useState<CustomStoreProps>(
    {} as CustomStoreProps,
  );
  const [integrationGroupsStore, setIntegrationGroupsStore] =
    useState<CustomStoreProps>({} as CustomStoreProps);
  const [officeStore, setOfficeStore] = useState<CustomStoreProps>(
    {} as CustomStoreProps,
  );
  const [practiceStore, setPracticeStore] = useState<CustomStoreProps>(
    {} as CustomStoreProps,
  );
  const [professionalStore, setProfessionalStore] = useState<CustomStoreProps>(
    {} as CustomStoreProps,
  );
  const [jobtitleStore, setJobtitleStore] = useState<CustomStoreProps>(
    {} as CustomStoreProps,
  );
  const [templateTypesStore, setTemplateTypesStore] =
    useState<CustomStoreProps>({} as CustomStoreProps);

  const { addToast } = useToast();

  const loadTemplates = useCallback(async () => {
    setLoading(true);
    const [
      template,
      areas,
      integrationGroups,
      offices,
      practices,
      professionals,
      jobtitles,
      templateTypes,
    ] = await Promise.all([
      api.get<TemplateProps>(`api/templates/${templateId}`),
      master.get(`master/areas`),
      master.get(`master/integration-groups`),
      master.get(`master/offices`),
      master.get(`master/practices`),
      master.get(`master/professionals`),
      master.get(`master/jobtitles`),
      api.get(`api/template-types`),
    ]);

    setTemplateTypeId(template.data.idTemplateType);
    setYear(template.data.year);
    setName(template.data.name);
    setProfessionalId(template.data.idProfessional || 0);
    setJobtitleId(template.data.idJobTitle || 0);
    setAreaId(template.data.idArea || 0);
    setIntegrationGroupId(template.data.idIntegrationGroup || 0);
    setOfficeId(template.data.idOffice || 0);
    setPracticeId(template.data.idPractice || 0);
    setIsFromDocx(template.data.isFromDocx);
    setDocxReference(template.data.docxReference || '');
    setAreaStore({
      store: new CustomStore({
        key: 'ID',
        loadMode: 'raw',
        load: async () => {
          return [{ ID: 0, Name: '(Unselected)' }, ...areas.data];
        },
      }),
      paginate: true,
    });
    setTemplateTypesStore({
      store: new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: async () => {
          return templateTypes.data;
        },
      }),
      paginate: true,
    });
    setIntegrationGroupsStore({
      store: new CustomStore({
        key: 'ID',
        loadMode: 'raw',
        load: async () => {
          return [{ ID: 0, Name: '(Unselected)' }, ...integrationGroups.data];
        },
      }),
      paginate: true,
    });
    setOfficeStore({
      store: new CustomStore({
        key: 'ID',
        loadMode: 'raw',
        load: async () => {
          return [{ ID: 0, Name: '(Unselected)' }, ...offices.data];
        },
      }),
      paginate: true,
    });
    setPracticeStore({
      store: new CustomStore({
        key: 'ID',
        loadMode: 'raw',
        load: async () => {
          return [{ ID: 0, Name: '(Unselected)' }, ...practices.data];
        },
      }),
      paginate: true,
    });
    setProfessionalStore({
      store: new CustomStore({
        key: 'ID',
        loadMode: 'raw',
        load: async () => {
          return [{ ID: 0, Name: '(Unselected)' }, ...professionals.data];
        },
      }),
      paginate: true,
    });
    setJobtitleStore({
      store: new CustomStore({
        key: 'ID',
        loadMode: 'raw',
        load: async () => {
          return [{ ID: 0, Name: '(Unselected)' }, ...jobtitles.data];
        },
      }),
      paginate: true,
    });

    setLoading(false);
  }, [templateId]);

  const onChangedTemplate = useCallback(
    async (field: string, value: any) => {
      await api.put(`api/templates/${templateId}`, {
        [field]: value,
      });

      addToast({
        type: 'success',
        title: 'Saved',
      });
    },
    [templateId, addToast],
  );

  useEffect(() => {
    loadTemplates();
  }, [loadTemplates]);

  return (
    <Container>
      {loading && (
        <ContainerLoading>
          <WhisperSpinner size={50} backColor="#8b0304" />
        </ContainerLoading>
      )}
      <HeaderComponent />
      <header>
        <div>
          <h1>TEMPLATE DETAILS</h1>
        </div>
      </header>
      <hr />
      <BoardBody>
        <Section>
          <div className="row">
            <div className="col">
              <FormGroup fieldSetLabel="TEMPLATE NAME">
                <TextBox
                  onChanged={value => onChangedTemplate('name', value)}
                  value={name}
                />
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <FormGroup fieldSetLabel="TEMPLATE TYPE">
                <SelectBox
                  stylingMode="outlined"
                  dataSource={templateTypesStore}
                  searchExpr={['name']}
                  minSearchLength={3}
                  showDataBeforeSearch
                  searchEnabled
                  valueExpr="id"
                  displayExpr="name"
                  onChanged={value =>
                    onChangedTemplate('idTemplateType', value)
                  }
                  value={templateTypeId}
                  readOnly
                />
              </FormGroup>
            </div>
            <div className="col">
              <FormGroup fieldSetLabel="REFERENCE YEAR">
                <TextBox
                  onChanged={value => onChangedTemplate('year', value)}
                  value={year}
                  readOnly
                />
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <FormGroup fieldSetLabel="AREA">
                Which area will have this template?
                <SelectBox
                  stylingMode="outlined"
                  dataSource={areaStore}
                  searchExpr={['Name']}
                  minSearchLength={3}
                  showDataBeforeSearch
                  searchEnabled
                  valueExpr="ID"
                  displayExpr="Name"
                  onChanged={value => onChangedTemplate('idArea', value)}
                  value={areaId}
                />
              </FormGroup>
            </div>
            <div className="col">
              <FormGroup fieldSetLabel="OFFICE">
                Which office will have this template?
                <SelectBox
                  stylingMode="outlined"
                  dataSource={officeStore}
                  searchExpr={['Name']}
                  minSearchLength={3}
                  showDataBeforeSearch
                  searchEnabled
                  valueExpr="ID"
                  displayExpr="Name"
                  onChanged={value => onChangedTemplate('idOffice', value)}
                  value={officeId}
                />
              </FormGroup>
            </div>
            <div className="col">
              <FormGroup fieldSetLabel="PRACTICE">
                Which practice will have this template?
                <SelectBox
                  stylingMode="outlined"
                  dataSource={practiceStore}
                  searchExpr={['Name']}
                  minSearchLength={3}
                  showDataBeforeSearch
                  searchEnabled
                  valueExpr="ID"
                  displayExpr="Name"
                  onChanged={value => onChangedTemplate('idPractice', value)}
                  value={practiceId}
                />
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <FormGroup fieldSetLabel="JOBTITLE">
                Which JobTitle will have this template?
                <SelectBox
                  stylingMode="outlined"
                  dataSource={jobtitleStore}
                  searchExpr={['Name']}
                  minSearchLength={3}
                  showDataBeforeSearch
                  searchEnabled
                  valueExpr="ID"
                  displayExpr="Name"
                  onChanged={value => onChangedTemplate('idJobTitle', value)}
                  value={jobtitleId}
                />
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <FormGroup fieldSetLabel="Template from Docx?">
                <p>Follow the steps below to use template from DOCX: </p>
                <ol style={{ marginLeft: 30 }}>
                  <li>Create your DOCX/Word file</li>
                  <li>
                    Fields to be replaced must stay amoung curly brackets. To
                    check all fields go to Fields page and take a look on Unique
                    column <br />
                    <span style={{ fontWeight: 700 }}>
                      E.g.: &#123;professional_name&#125;, &#123;jobtitle&#125;,
                      &#123;salary_average&#125;
                    </span>
                  </li>
                  <li>
                    Upload the file in the main folder on file manager below
                  </li>
                  <li>
                    If it has multiple docx files, only last template will be
                    used
                  </li>
                </ol>
              </FormGroup>
            </div>
          </div>
        </Section>
      </BoardBody>

      <header>
        <div>
          <h1>FILE MANAGER</h1>
        </div>
      </header>
      <hr />
      <BoardBody>
        <Section>
          <TemplateFileManager templateId={templateId} />
        </Section>
      </BoardBody>
    </Container>
  );
};
