import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  align-items: left;
  justify-content: flex-start;

  .dx-popup-content {
    height: auto !important;
    flex: 1;
  }
  .dx-overlay-content {
    display: flex !important;
  }

  div {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-self: center;

    p {
      margin: 0;
      font-size: 12px;
      color: #999;
      font-weight: 700;
    }

    .name {
      font-size: 14px;
      color: #333;
    }
  }
`;
