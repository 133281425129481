import styled from 'styled-components';

export const FileManagerImg = styled.div`
  margin: auto;
  height: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  input {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
    outline: 0;
    border: 0;
    padding: 8px !important;
    -webkit-transition: box-shadow 0.15s;
    transition: box-shadow 0.15s;
    vertical-align: middle;
    -webkit-appearance: none;
    box-shadow: 0 0 0 2px transparent inset, 0 0 0 1px #e0e0e0 inset;
    background-color: #e9ecef;
    /* margin: 15px 0; */
    border-radius: 6px;
    cursor: pointer;
  }

  input:focus {
    box-shadow: 0 0 0 2px #8b0304 inset, 0 0 0 1px #e0e0e0 inset;
  }

  img {
    max-height: 100%;
    max-width: 100%;
    margin: 0;
    padding: 5px;
  }
`;
