import React, { useState, useCallback } from 'react';

import FileManager, { Permissions } from 'devextreme-react/file-manager';
import { Popup } from 'devextreme-react/popup';
import CustomFileSystemProvider from 'devextreme/file_management/custom_provider';

import { useToast } from '../../hooks/toast';
import api from '../../services/api';

import { FileManagerImg } from './styles';

interface TemplateFileManagerProps {
  templateId: string;
}

interface TemplateFileProps {
  id: number;
  idTemplate: number;
  idProfessional: number;
  fileName: string;
  fileExtension?: string;
  fileSize?: string;
  fileUri?: string;
  fileReference?: string;
  isDirectory: boolean;
  createdAt: Date;
}

interface FileManagerProps {
  id: number;
  name: string;
  isDirectory: boolean;
  size: number;
  url: string;
}

export const TemplateFileManager: React.FC<TemplateFileManagerProps> = ({
  templateId,
}) => {
  const [displayFile, setDisplayFile] = useState<FileManagerProps>(
    {} as FileManagerProps,
  );
  const [openDisplayFile, setOpenDisplayFile] = useState(false);

  const [customFileProvider] = useState(
    new CustomFileSystemProvider({
      keyExpr: 'id',
      dateModifiedExpr: 'updatedAt',
      // Function to get file system items
      getItems: async pathInfo => {
        const params: { idParent: string } = {} as { idParent: string };

        if (pathInfo.key) params.idParent = pathInfo.key;

        const { data } = await api.get<TemplateFileProps[]>(
          `/api/templates/${templateId}/files`,
          {
            params,
          },
        );

        return data.map(file => ({
          id: file.id,
          name: file.fileName,
          isDirectory: file.isDirectory,
          size: file.fileSize,
          url: `${process.env.REACT_APP_API_URL}/api/templates/${templateId}/files/download/${file.fileReference}?code=${process.env.REACT_APP_API_FILE_ACCESS_KEY}`,
        }));
      },
      // Functions to handle file operations
      createDirectory: async (parentDirectory, directoryName) => {
        await api.post(`/api/templates/${templateId}/files/directory`, {
          directoryName,
          idParent: parentDirectory.key,
        });
      },
      deleteItem: async item => {
        await api.delete(`/api/templates/${templateId}/files/${item.key}`);
      },
      moveItem: async (item, directory) => {
        await api.patch(
          `/api/templates/${templateId}/files/${item.key}/parent`,
          {
            idParent: directory.key === '' ? null : directory.key,
          },
        );
      },
      downloadItems: async items => {
        const ids = items.map(x => x.key);
        const { data, headers } = await api.get(
          `/api/templates/${templateId}/files/download?files=[${ids.join(
            ',',
          )}]`,
          {
            responseType: 'blob',
          },
        );

        const downloadUrl = window.URL.createObjectURL(new Blob([data]));

        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', headers['x-file-name']);
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
      uploadFileChunk: async (file, uploadInfo, directory) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('idParent', directory.key);
        await api.post(`/api/templates/${templateId}/files/file`, formData, {
          headers: {
            'content-type': 'multipart/form-data',
            'X-CHUNK-TOTAL': uploadInfo.chunkCount?.toString(),
            'X-CHUNK-INDEX': (uploadInfo.chunkIndex + 1)?.toString(),
          },
        });
      },
    }),
  );

  const handleSelectedFileOpened = useCallback(e => {
    setDisplayFile(e.file.dataItem);
    setOpenDisplayFile(true);
  }, []);

  const { addToast } = useToast();

  return (
    <>
      <FileManager
        fileSystemProvider={customFileProvider}
        onSelectedFileOpened={handleSelectedFileOpened}
        allowedFileExtensions={['.docx']}
      >
        <Permissions delete upload download />
      </FileManager>

      <Popup
        closeOnOutsideClick
        title={displayFile?.name || ''}
        visible={openDisplayFile}
        onHiding={() => setOpenDisplayFile(false)}
      >
        <FileManagerImg>
          <input
            value={displayFile?.url || ''}
            readOnly
            onClick={() => {
              navigator.clipboard.writeText(displayFile.url);
              addToast({
                type: 'success',
                title: 'Copied to clipboard',
              });
            }}
          />
          <img src={displayFile?.url || ''} alt={displayFile?.name || ''} />
        </FileManagerImg>
      </Popup>
    </>
  );
};
