import React, { BaseHTMLAttributes } from 'react';
import {
  ToolBox as ToolboxContainer,
  ToolItem as ToolItemContainer,
  Badge,
  Container,
} from './styles';

export const ToolBox: React.FC = ({ children, ...rest }) => {
  return <ToolboxContainer {...rest}>{children}</ToolboxContainer>;
};

interface ToolItemProps extends BaseHTMLAttributes<HTMLDivElement> {
  selected?: boolean;
  icon?: React.ReactNode;
  title: string;
  disabled?: boolean;
  primary?: boolean;
  badge?: string;
  secondary?: boolean;
}

export const ToolItem: React.FC<ToolItemProps> = ({
  icon,
  title,
  selected = false,
  disabled = false,
  primary = false,
  secondary = false,
  badge = '',
  ...rest
}) => {
  return (
    <Container>
      {badge && <Badge>{badge}</Badge>}
      <ToolItemContainer
        primary={primary}
        disabled={disabled}
        selected={selected}
        secondary={secondary}
        {...rest}
      >
        {!!icon && icon}
        {title}
      </ToolItemContainer>
    </Container>
  );
};
