import React, { useCallback, useState, useEffect } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogProps,
} from '@material-ui/core';

import { WhisperSpinner } from 'react-spinners-kit';
import CustomStore from 'devextreme/data/custom_store';

import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import { getYear } from 'date-fns';
import { FormGroup } from '../../../components/FormGroup';
import { Button } from '../../../components/Button';

import api from '../../../services/api';
import master from '../../../services/master';
import { useToast } from '../../../hooks/toast';

interface DialogCustomProps extends DialogProps {
  handleClose(): void;
  onSubmitted(): void;
}

interface CustomStoreProps {
  store: CustomStore;
  paginate: boolean;
}

export const DialogAddLetter: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
  onSubmitted,
}) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const [professional, setProfessional] = useState(0);
  const [templateId, setTemplateId] = useState(0);
  const [year, setYear] = useState(getYear(new Date()));
  const [templates, setTemplates] = useState<CustomStoreProps>(
    {} as CustomStoreProps,
  );
  const [professionals, setProfessionals] = useState<CustomStoreProps>(
    {} as CustomStoreProps,
  );

  useEffect(() => {
    async function loadTemplates() {
      setLoading(true);

      const templatesSource = await api.get('/api/templates');

      const professionalsSource = await master.get('/master/professionals');

      setTemplates({
        store: new CustomStore({
          key: 'ID',
          loadMode: 'raw',
          load: async () => {
            return templatesSource.data;
          },
        }),
        paginate: true,
      });
      setProfessionals({
        store: new CustomStore({
          key: 'ID',
          loadMode: 'raw',
          load: async () => {
            return professionalsSource.data;
          },
        }),
        paginate: true,
      });
      setLoading(false);
    }

    loadTemplates();
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!professional || !templateId || !year) {
      addToast({
        type: 'error',
        title: 'Please, fill all fields',
      });

      return;
    }

    setLoading(true);
    try {
      await api.post('/api/letters', {
        idProfessional: professional,
        idTemplate: templateId,
        year,
      });

      addToast({
        type: 'success',
        title: 'Letter created!',
      });
    } catch {
      addToast({
        type: 'error',
        title: 'Something went wrong...',
      });
      setLoading(false);
      return;
    }

    setLoading(false);
    handleClose();
    onSubmitted();
  }, [professional, templateId, year, addToast, onSubmitted, handleClose]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle id="form-dialog-title">Add new letter</DialogTitle>
      <DialogContent>
        {!loading ? (
          <>
            <FormGroup fieldSetLabel="Professional">
              <SelectBox
                onValueChanged={e => setProfessional(e.value)}
                defaultValue={professional}
                stylingMode="outlined"
                dataSource={professionals}
                searchExpr={['Name']}
                minSearchLength={3}
                showDataBeforeSearch
                searchEnabled
                valueExpr="ID"
                displayExpr="Name"
              />
            </FormGroup>
            <FormGroup fieldSetLabel="Template">
              <SelectBox
                onValueChanged={e => setTemplateId(e.value)}
                defaultValue={templateId}
                stylingMode="outlined"
                dataSource={templates}
                searchExpr={['name']}
                minSearchLength={3}
                showDataBeforeSearch
                searchEnabled
                valueExpr="id"
                displayExpr="name"
              />
            </FormGroup>
            <FormGroup fieldSetLabel="Year">
              <TextBox
                onValueChanged={e => setYear(e.value)}
                defaultValue={year}
                stylingMode="outlined"
              />
            </FormGroup>
          </>
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <WhisperSpinner size={24} backColor="#8b0304" frontColor="#fff" />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleSubmit} primary loading={loading}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
