import styled, { css } from 'styled-components';

interface ContainerProps {
  type: 'success' | 'info' | 'error' | 'warning';
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  border-radius: 6px;
  padding: 6px 16px;
  flex-direction: column;
  font-size: 14px;

  h1 {
    font-size: 1rem;
    font-weight: 700;
  }

  ${props =>
    props.type === 'success' &&
    css`
      background-color: rgb(237, 247, 237);
      color: rgb(30, 70, 32);
    `}

  ${props =>
    props.type === 'info' &&
    css`
      background-color: rgb(229, 246, 253);
      color: rgb(1, 67, 97);
    `}

    ${props =>
    props.type === 'warning' &&
    css`
      background-color: rgb(255, 244, 229);
      color: rgb(102, 60, 0);
    `}

    ${props =>
    props.type === 'error' &&
    css`
      background-color: rgb(253, 237, 237);
      color: rgb(95, 33, 32);
    `}
`;
