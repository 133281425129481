import React, { useCallback, useEffect, useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogProps,
} from '@material-ui/core';

import CustomStore from 'devextreme/data/custom_store';

import { TextBox } from 'devextreme-react/text-box';
import { CheckBox } from 'devextreme-react/check-box';
import { TagBox } from 'devextreme-react/tag-box';

import { WhisperSpinner } from 'react-spinners-kit';

import { Button } from '../../../components/Button';
import { FormGroup } from '../../../components/FormGroup';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

interface DialogCustomProps extends DialogProps {
  handleClose(): void;
  onSubmitted(): void;
}

interface CustomStoreProps {
  store: CustomStore;
  paginate: boolean;
}

interface FieldProps {
  id: number;
  isActive: boolean;
}

export const DialogAddType: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
  onSubmitted,
}) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const [name, setName] = useState('');
  const [hasEvaluations, setHasEvaluations] = useState(false);
  const [fields, setFields] = useState<number[]>([]);

  const [fieldsSource, setFieldsSource] = useState<CustomStoreProps>(
    {} as CustomStoreProps,
  );

  const loadFields = useCallback(async () => {
    setFieldsSource({
      store: new CustomStore({
        key: 'ID',
        loadMode: 'raw',
        load: async () => {
          const { data } = await api.get<FieldProps[]>(`api/fields`);

          return data.filter(x => x.isActive);
        },
      }),
      paginate: true,
    });
  }, []);

  useEffect(() => {
    loadFields();
  }, [loadFields]);

  const handleSubmit = useCallback(async () => {
    if (name && fields) {
      setLoading(true);

      await api.post('/api/template-types', {
        name,
        hasEvaluations,
        fields,
      });

      addToast({
        title: 'Type created!',
        type: 'success',
      });
      setLoading(false);
      onSubmitted();
      handleClose();
    }
  }, [onSubmitted, handleClose, addToast, name, hasEvaluations, fields]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle id="form-dialog-title">Add type</DialogTitle>
      <DialogContent>
        {!loading ? (
          <>
            <FormGroup fieldSetLabel="Type name">
              <TextBox
                stylingMode="outlined"
                onValueChanged={e => setName(e.value)}
                placeholder="Ex: Bonus, Promotion..."
                defaultValue={name}
              />
            </FormGroup>
            <FormGroup fieldSetLabel="Has evaluations?">
              <CheckBox
                onValueChanged={e => setHasEvaluations(e.value)}
                defaultValue={hasEvaluations}
              />
            </FormGroup>
            <FormGroup fieldSetLabel="Fields">
              <TagBox
                value={fields}
                stylingMode="outlined"
                dataSource={fieldsSource}
                searchExpr={['name']}
                minSearchLength={3}
                showDataBeforeSearch
                searchEnabled
                valueExpr="id"
                displayExpr="name"
                onValueChanged={e => setFields(e.value)}
              />
            </FormGroup>
          </>
        ) : (
          <WhisperSpinner size={24} backColor="#8b0304" frontColor="#fff" />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} primary loading={loading}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
