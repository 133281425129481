import React, { useCallback } from 'react';

import Dropzone from 'react-dropzone';

import { DropContainer, UploadMessage } from './styles';

interface UploadProps {
  onUpload(files: File[]): void;
}

export const Upload: React.FC<UploadProps> = ({ onUpload }) => {
  const renderDragMessage = useCallback(
    (isDragActive: boolean, isDragRejest: boolean) => {
      if (!isDragActive) {
        return (
          <UploadMessage type="default">
            Click or drop your file here
          </UploadMessage>
        );
      }

      if (isDragRejest) {
        return <UploadMessage type="error">File not allowed</UploadMessage>;
      }

      return <UploadMessage type="success">Drop your file here</UploadMessage>;
    },
    [],
  );

  return (
    <Dropzone
      accept=".csv, application/vnd.ms-excel, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      onDropAccepted={files => onUpload(files)}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <DropContainer
          {...getRootProps()}
          isDragActive={isDragActive}
          isDragReject={isDragReject}
        >
          <input {...getInputProps()} data-testid="upload" multiple={false} />
          {renderDragMessage(isDragActive, isDragReject)}
        </DropContainer>
      )}
    </Dropzone>
  );
};
