import styled from 'styled-components';

export const Container = styled.div`
  & > .dx-texteditor.dx-editor-outlined {
    background-color: #dedede;
    box-shadow: none;
  }

  & > .dx-textbox {
    line-height: 0;
    font-size: 14px;

    input {
      padding: 10px 10px 9px !important;
      font-size: 14px;
    }
  }
`;
