import axios from 'axios';

const UNAUTHORIZED = 401;
const FORBBIDEN = 403;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      const { status } = error.response;
      if (status === UNAUTHORIZED || status === FORBBIDEN) {
        localStorage.clear();
        window.location.href = '/';
      }
    }

    return Promise.reject(error);
  },
);
api.defaults.headers.common.Authorization =
  localStorage.getItem('accessToken') || '';

export default api;

export const Permissions = {
  Admin: 'API.Letter.Admin',
};
