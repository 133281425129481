import React, { useCallback, useState, useEffect } from 'react';

import DataGrid, { Column, Paging } from 'devextreme-react/data-grid';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogProps,
  IconButton,
  Chip,
  Tooltip,
} from '@material-ui/core';

import { BiDownload, BiRefresh } from 'react-icons/bi';
import { WhisperSpinner } from 'react-spinners-kit';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { ToolBox, ToolItem } from '../../../components/Toolbox';

import { Button } from '../../../components/Button';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

interface DialogCustomProps extends DialogProps {
  handleClose(): void;
}

export const DialogFiles: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
}) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const [files, setFiles] = useState<DataSource>({} as DataSource);

  useEffect(() => {
    async function loadFiles() {
      setLoading(true);

      const filesStore = new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: async () => {
          setLoading(true);

          const { data } = await api.get('/api/letter-files');
          setLoading(false);

          return data;
        },
      });

      setFiles(
        new DataSource({
          store: filesStore,
          paginate: true,
          reshapeOnPush: true,
        }),
      );
      setLoading(false);
    }

    loadFiles();
  }, []);

  const handleDownload = useCallback(async id => {
    const response = await api.post(
      `/api/letter-files/${id}/download`,
      {},
      {
        responseType: 'blob',
      },
    );

    setLoading(false);

    const headerLine = response.headers['content-disposition'];
    const headerPeaces = headerLine.split(';');
    const fileNamePeace = headerPeaces[1].split('=');
    const filename = fileNamePeace[1];

    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }, []);

  const commandColumnRender = useCallback(
    e => (
      <IconButton
        aria-label="Download"
        size="small"
        onClick={() => e.data.isSuccess && handleDownload(e.key)}
        disabled={!e.data.isSuccess}
      >
        <BiDownload size={20} />
      </IconButton>
    ),
    [handleDownload],
  );

  const statusCell = useCallback(e => {
    if (e.data.isLoading)
      return (
        <Chip
          style={{ backgroundColor: '#edce61' }}
          color="primary"
          label="Pending"
        />
      );
    if (e.data.isSuccess)
      return (
        <Chip
          style={{ backgroundColor: '#4cb56f' }}
          color="primary"
          label="Available"
        />
      );

    return (
      <Chip
        style={{ backgroundColor: '#d15c56' }}
        color="primary"
        label="Error"
      />
    );
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle id="form-dialog-title">Your files</DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        {!loading ? (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '0 50px',
              }}
            >
              <Tooltip title="Refresh">
                <IconButton
                  aria-label="Edit letter"
                  onClick={() => files.reload()}
                  size="small"
                  style={{ backgroundColor: '#8b0304', color: '#fff' }}
                >
                  <BiRefresh />
                </IconButton>
              </Tooltip>
            </div>
            <DataGrid
              showBorders={false}
              showColumnHeaders
              hoverStateEnabled
              dataSource={files}
            >
              <Paging pageSize={30} />

              <Column dataField="id" visible={false} />
              <Column dataField="fileName" caption="File Name" />
              <Column caption="Status" cellRender={statusCell} />
              <Column
                dataField="createdAt"
                dataType="date"
                caption="Date"
                format="dd/MMM/yyyy HH:mm"
              />
              <Column
                type="buttons"
                cellRender={commandColumnRender}
                width={120}
              />
            </DataGrid>
          </>
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <WhisperSpinner size={24} backColor="#8b0304" frontColor="#fff" />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
