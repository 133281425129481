import styled, { css } from 'styled-components';

interface ToolItemProps {
  selected: boolean;
  disabled: boolean;
  primary: boolean;
  secondary: boolean;
}

export const ToolBox = styled.div`
  display: flex;
  flex-direction: row;

  & > div:not(:last-child) {
    margin-right: 5px;
  }
`;

export const Container = styled.div`
  position: relative;
`;

export const ToolItem = styled.div<ToolItemProps>`
  padding: 3px 9px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.23);
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 80px;
  min-height: 60px;
  background-color: #fff;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
      0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  text-transform: none;
  /* padding: 0; */
  font-weight: 400;

  & > svg {
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 14px;
  }

  ${props =>
    props.selected &&
    css`
      border: 2px solid #8b0304;
    `}

  ${props =>
    props.primary &&
    css`
      background-color: #8b0304;
      color: #fff;
    `}

    ${props =>
    props.secondary &&
    css`
      background-color: #313131;
      color: #fff;
    `}

  ${props =>
    props.disabled &&
    css`
      cursor: default;
      background-color: #cccccc;
      border: 1px solid #999;
      box-shadow: none !important;
      color: rgba(0, 0, 0, 0.4);
    `}
`;

export const Badge = styled.div`
  height: 20px;
  display: flex;
  padding: 0 6px;
  z-index: 1;
  position: absolute;
  flex-wrap: wrap;
  font-size: 0.75rem;
  min-width: 20px;
  box-sizing: border-box;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  font-weight: 700;
  line-height: 1;
  align-content: center;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  background-color: #8b0304;
  color: #fff;
  top: -5px;
  right: -5px;
  cursor: default;
`;
