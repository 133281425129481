import React, { FieldsetHTMLAttributes } from 'react';
import { Container } from './styles';

interface EvaluationLookupProps
  extends FieldsetHTMLAttributes<HTMLFieldSetElement> {
  period: string;
  year: string;
  projectCode?: string;
  status: string;
}

export const EvaluationLookup: React.FC<EvaluationLookupProps> = ({
  period,
  year,
  projectCode,
  status,
}) => {
  return (
    <Container>
      <div>
        <p className="name">
          Cycle: {period} - {year}
        </p>
        <p>Status: {status}</p>
        <p>{projectCode}</p>
      </div>
    </Container>
  );
};
