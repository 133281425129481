import React, { useCallback, useState, useEffect } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogProps,
} from '@material-ui/core';

import { WhisperSpinner } from 'react-spinners-kit';
import CustomStore from 'devextreme/data/custom_store';

import { Button } from '../../../components/Button';
import { FormGroup } from '../../../components/FormGroup';
import { SelectBox } from '../../../components/SelectBox';
import { TagBox } from '../../../components/TagBox';
import { TextBox } from '../../../components/TextBox';
import { CheckBox } from '../../../components/CheckBox';
import { EvaluationLookup } from '../../../components/EvaluationLookup';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

interface DialogCustomProps extends DialogProps {
  letterId: number;
  handleClose(): void;
  onSubmitted(): void;
}

interface CustomStoreProps {
  store: CustomStore;
  paginate: boolean;
}

interface ILetterField {
  id: number;
  idField: number;
  value: string;
  idLetter: number;
}

interface ILetterEvaluation {
  id: number;
  idEvaluation: number;
  projectCode: string;
  idLetter: number;
}

interface ILetter {
  letterEvaluations: ILetterEvaluation[];
  letterFields: ILetterField[];
  id: number;
  idProfessional: number;
  idTemplate: number;
  year: number;
  isActive: boolean;
  professional: IProfessional;
  template: TemplateProps;
}

interface IProfessional {
  id: number;
  name: string;
  login: string;
  jobTitle: IJobTitle;
  email: string;
}

interface IJobTitle {
  id: number;
  name: string;
}

interface IEvaluation {
  id: number;
  totalAverage: number;
  evaluationStatus: IEvaluationStatus;
  cycle: ICycle;
  displayName?: string;
  project?: IProject;
}

interface ICycle {
  id: number;
  period: number;
  year: number;
}

interface IEvaluationStatus {
  id: number;
  description: string;
}

interface IProject {
  id: number;
  projectCode: string;
  projectName: string;
}

interface FieldProps {
  id: number;
  name: string;
  replacer: string;
  allowEdit: boolean;
  createdAt: string;
}

interface TemplateTypeFieldsProps {
  id: number;
  field: FieldProps;
}

interface TemplateTypeProps {
  id: number;
  name: string;
  hasEvaluations: boolean;
  templateTypeFields: TemplateTypeFieldsProps[];
}

interface TemplateProps {
  name: string;
  idTemplateType: number;
  year: number;
  idProfessional: number | null;
  idJobTitle: number | null;
  idArea: number | null;
  idIntegrationGroup: number | null;
  idOffice: number | null;
  idPractice: number | null;
  text: string;
  templateType: TemplateTypeProps;
}

export const DialogEditLetter: React.FC<DialogCustomProps> = ({
  letterId,
  open,
  handleClose,
  onSubmitted,
}) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const [professionalName, setProfessionalName] = useState('');
  const [templateId, setTemplateId] = useState(0);
  const [year, setYear] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [hasEvaluations, setHasEvaluations] = useState(false);
  const [letterFields, setLetterFields] = useState<ILetterField[]>([]);
  const [letterEvaluations, setLetterEvaluations] = useState<number[]>([]);

  const [fields, setFields] = useState<FieldProps[]>([]);
  const [evaluations, setEvaluations] = useState<CustomStoreProps>(
    {} as CustomStoreProps,
  );
  const [templates, setTemplates] = useState<CustomStoreProps>(
    {} as CustomStoreProps,
  );

  useEffect(() => {
    async function loadLetter() {
      setLoading(true);

      const { data } = await api.get<ILetter>(`/api/letters/${letterId}`);

      const [fieldsSource, evaluationsSource, templatesSource] =
        await Promise.all([
          api.get<FieldProps[]>('/api/fields'),
          api.get<IEvaluation[]>(`/api/evaluations/${data.idProfessional}`),
          api.get('/api/templates', {
            params: {
              idTemplateType: data.template.idTemplateType,
            },
          }),
        ]);

      const allowedFields = data.template.templateType.templateTypeFields.map(
        x => x.field.id,
      );

      setProfessionalName(data.professional.name);
      setTemplateId(data.idTemplate);
      setYear(data.year);
      setIsActive(data.isActive);
      setHasEvaluations(data.template.templateType.hasEvaluations);
      setLetterEvaluations(data.letterEvaluations.map(x => x.idEvaluation));
      setLetterFields(data.letterFields);
      setFields(fieldsSource.data.filter(x => allowedFields.includes(x.id)));
      setEvaluations({
        store: new CustomStore({
          key: 'ID',
          loadMode: 'raw',
          load: async () => {
            return evaluationsSource.data.map(x => ({
              ...x,
              displayName: `${x.cycle.period} - ${x.cycle.year} ${
                x.project?.projectCode || ''
              }`,
            }));
          },
        }),
        paginate: true,
      });
      setTemplates({
        store: new CustomStore({
          key: 'ID',
          loadMode: 'raw',
          load: async () => {
            return templatesSource.data;
          },
        }),
        paginate: true,
      });
      setLoading(false);
    }

    loadLetter();
  }, [letterId]);

  const handleChangedLetter = useCallback(
    async (field, value) => {
      await api.put(`api/letters/${letterId}`, {
        [field]: value,
      });

      addToast({
        type: 'success',
        title: 'Saved',
      });
      onSubmitted();
    },
    [letterId, addToast, onSubmitted],
  );

  const handleChangedLetterField = useCallback(
    async (fieldId, value) => {
      await api.patch(`/api/letters/${letterId}/fields/${fieldId}`, {
        value,
      });

      addToast({
        type: 'success',
        title: 'Saved',
      });
    },
    [letterId, addToast],
  );

  const onAddEvaluation = useCallback(
    async value => {
      await api.post(`/api/letters/${letterId}/evaluations`, {
        idEvaluation: value,
      });

      addToast({
        type: 'success',
        title: 'Saved',
      });
    },
    [letterId, addToast],
  );

  const onRemoveEvaluation = useCallback(
    async value => {
      await api.delete(`/api/letters/${letterId}/evaluations/${value}`);

      addToast({
        type: 'success',
        title: 'Saved',
      });
    },
    [letterId, addToast],
  );

  const evaluationLookup = useCallback(
    data => (
      <EvaluationLookup
        period={data.cycle.period}
        year={data.cycle.year}
        status={data.evaluationStatus.description}
        projectCode={data.project?.projectCode}
      />
    ),
    [],
  );

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle id="form-dialog-title">
        {!loading && `Letter - ${professionalName} - ${year}`}
      </DialogTitle>
      <DialogContent>
        {!loading ? (
          <>
            <FormGroup fieldSetLabel="Template">
              <SelectBox
                onChanged={value => handleChangedLetter('idTemplate', value)}
                value={templateId}
                stylingMode="outlined"
                dataSource={templates}
                searchExpr={['name']}
                minSearchLength={3}
                showDataBeforeSearch
                searchEnabled
                valueExpr="id"
                displayExpr="name"
              />
            </FormGroup>
            <FormGroup fieldSetLabel="Is Active?">
              <CheckBox
                onChanged={value => handleChangedLetter('isActive', value)}
                value={isActive}
              />
            </FormGroup>
            {hasEvaluations && (
              <FormGroup fieldSetLabel="Evaluations">
                <TagBox
                  value={letterEvaluations}
                  stylingMode="outlined"
                  dataSource={evaluations}
                  searchExpr={['displayName']}
                  minSearchLength={3}
                  showDataBeforeSearch
                  searchEnabled
                  valueExpr="id"
                  displayExpr="displayName"
                  itemRender={data => evaluationLookup(data)}
                  onAdd={onAddEvaluation}
                  onRemove={onRemoveEvaluation}
                />
              </FormGroup>
            )}

            <hr />
            {fields
              .filter(x => x.allowEdit)
              .map(field => (
                <FormGroup fieldSetLabel={field.name} key={field.id}>
                  <TextBox
                    onChanged={value =>
                      handleChangedLetterField(field.id, value)
                    }
                    value={
                      letterFields.find(x => x.idField === field.id)?.value
                    }
                  />
                </FormGroup>
              ))}
          </>
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <WhisperSpinner size={24} backColor="#8b0304" frontColor="#fff" />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
