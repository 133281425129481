import styled from 'styled-components';

export const Container = styled.div`
  & > .dx-texteditor.dx-editor-outlined {
    background-color: #928989;
    box-shadow: none;
  }

  & > .dx-textbox {
    line-height: 0;
    font-size: 14px;

    input {
      padding: 10px 10px 9px !important;
      font-size: 14px;
    }
  }
`;

export const Input = styled.input`
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  background-color: #dedede;
  color: #333;
  font-family: 'Roboto Condensed', sans-serif;
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    box-shadow: inset 0 0 0 2px #8b0304;
    outline: none;
  }
`;
