import React, { BaseHTMLAttributes } from 'react';

import { Container } from './styles';

interface AlertProps extends BaseHTMLAttributes<HTMLDivElement> {
  type: 'success' | 'info' | 'error' | 'warning';
}

export const Alert: React.FC<AlertProps> = ({ children, type, ...rest }) => {
  return (
    <Container type={type} {...rest}>
      {children}
    </Container>
  );
};
