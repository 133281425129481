import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Tooltip from '@material-ui/core/Tooltip';
import { FiHome, FiLogOut } from 'react-icons/fi';

import { BiCodeAlt, BiEnvelope, BiCodeCurly, BiDetail } from 'react-icons/bi';
import { useAuth } from '../../hooks/auth';

import {
  Container,
  Control,
  ControlComponent,
  NavigationItemsArea,
  ActionIconWrapper,
  NotificationsComponent,
  NavigationDialogItemComponent,
} from './styles';

export const Surface: React.FC = () => {
  const history = useHistory();

  const { signOut } = useAuth();

  const handleHome = useCallback(() => {
    history.push('/');
  }, [history]);

  const handleBonus = useCallback(() => {
    history.push('/letters');
  }, [history]);

  const handleTemplate = useCallback(() => {
    history.push('/templates');
  }, [history]);

  const handleTemplateType = useCallback(() => {
    history.push('/template-types');
  }, [history]);

  const handleTemplateField = useCallback(() => {
    history.push('/fields');
  }, [history]);

  return (
    <Container>
      <Control>
        <ControlComponent>
          <NavigationItemsArea>
            <ActionIconWrapper>
              <NotificationsComponent>
                <span>
                  <div className="iconContainer">
                    <Tooltip title="Home" placement="right" color="red">
                      <NavigationDialogItemComponent onClick={handleHome}>
                        <FiHome color="#9D9D9D" />
                      </NavigationDialogItemComponent>
                    </Tooltip>
                  </div>
                  <div className="iconContainer">
                    <Tooltip title="Letters" placement="right" color="red">
                      <NavigationDialogItemComponent onClick={handleBonus}>
                        <BiEnvelope color="#9D9D9D" />
                      </NavigationDialogItemComponent>
                    </Tooltip>
                  </div>
                  <div className="iconContainer">
                    <Tooltip title="Templates" placement="right" color="red">
                      <NavigationDialogItemComponent onClick={handleTemplate}>
                        <BiCodeAlt color="#9D9D9D" />
                      </NavigationDialogItemComponent>
                    </Tooltip>
                  </div>
                  <div className="iconContainer">
                    <Tooltip
                      title="Template type"
                      placement="right"
                      color="red"
                    >
                      <NavigationDialogItemComponent
                        onClick={handleTemplateType}
                      >
                        <BiDetail color="#9D9D9D" />
                      </NavigationDialogItemComponent>
                    </Tooltip>
                  </div>
                  <div className="iconContainer">
                    <Tooltip title="Fields" placement="right" color="red">
                      <NavigationDialogItemComponent
                        onClick={handleTemplateField}
                      >
                        <BiCodeCurly color="#9D9D9D" />
                      </NavigationDialogItemComponent>
                    </Tooltip>
                  </div>
                  <div className="iconContainer">
                    <Tooltip title="Log out" placement="right">
                      <NavigationDialogItemComponent
                        onClick={signOut}
                        style={{ position: 'absolute', bottom: 32 }}
                      >
                        <FiLogOut color="#9D9D9D" />
                      </NavigationDialogItemComponent>
                    </Tooltip>
                  </div>
                </span>
              </NotificationsComponent>
            </ActionIconWrapper>
          </NavigationItemsArea>
        </ControlComponent>
      </Control>
    </Container>
  );
};
