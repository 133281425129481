import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import { ApplicationLayers } from '../components/ApplicationLayers';
import { SignIn } from '../pages/SignIn';
import { Home } from '../pages/Home';
import { Template } from '../pages/Template';
import { EditTemplate } from '../pages/Template/EditTemplate';
import { Field } from '../pages/Field';
import { Letter } from '../pages/Letter';
import { TemplateType } from '../pages/TemplateType';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/auth/signin" exact component={SignIn} />

    <ApplicationLayers>
      <Route path="/app" exact component={Home} isPrivate />
      <Route path="/letters" exact component={Letter} isPrivate />
      <Route path="/templates" exact component={Template} isPrivate />
      <Route path="/templates/:id" exact component={EditTemplate} isPrivate />
      <Route path="/fields" exact component={Field} isPrivate />
      <Route path="/template-types" exact component={TemplateType} isPrivate />
    </ApplicationLayers>
  </Switch>
);

export default Routes;
