import React, { useCallback, useEffect, useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogProps,
} from '@material-ui/core';

import { TextBox } from 'devextreme-react/text-box';
import { SelectBox } from 'devextreme-react/select-box';
import { NumberBox } from 'devextreme-react/number-box';
import CustomStore from 'devextreme/data/custom_store';

import { WhisperSpinner } from 'react-spinners-kit';

import { getYear } from 'date-fns';
import { Button } from '../../../components/Button';
import { FormGroup } from '../../../components/FormGroup';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

interface DialogCustomProps extends DialogProps {
  handleClose(): void;
  onSubmitted(): void;
}

interface CustomStoreProps {
  store: CustomStore;
  paginate: boolean;
}

export const DialogAddTemplate: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
  onSubmitted,
}) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const [name, setName] = useState('');
  const [year, setYear] = useState(getYear(new Date()));
  const [typeId, setTypeId] = useState(0);
  const [typesSource, setTypesSource] = useState<CustomStoreProps>(
    {} as CustomStoreProps,
  );

  const loadTypeSource = useCallback(async () => {
    setTypesSource({
      store: new CustomStore({
        key: 'ID',
        loadMode: 'raw',
        load: async () => {
          const { data } = await api.get(`api/template-types`);

          return data;
        },
      }),
      paginate: true,
    });
  }, []);

  useEffect(() => {
    loadTypeSource();
  }, [loadTypeSource]);

  const handleSubmit = useCallback(async () => {
    if (name && year && typeId) {
      setLoading(true);

      await api.post('/api/templates', {
        name,
        idTemplateType: typeId,
        year,
      });

      addToast({
        title: 'Template created!',
        type: 'success',
      });
      setLoading(false);
      onSubmitted();
      handleClose();
    }
  }, [onSubmitted, handleClose, addToast, name, typeId, year]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle id="form-dialog-title">Add template</DialogTitle>
      <DialogContent>
        {!loading ? (
          <>
            <FormGroup fieldSetLabel="Template name">
              <TextBox
                stylingMode="outlined"
                onValueChanged={e => setName(e.value)}
                placeholder="Ex: Consultancy Brazil template"
                defaultValue={name}
              />
            </FormGroup>
            <FormGroup fieldSetLabel="Template type">
              <SelectBox
                stylingMode="outlined"
                dataSource={typesSource}
                searchExpr={['name']}
                minSearchLength={3}
                showDataBeforeSearch
                searchEnabled
                valueExpr="id"
                displayExpr="name"
                onValueChanged={e => setTypeId(e.value)}
                defaultValue={typeId}
              />
            </FormGroup>
            <FormGroup fieldSetLabel="Reference Year">
              <NumberBox
                stylingMode="outlined"
                onValueChanged={e => setYear(e.value)}
                defaultValue={year}
                showSpinButtons
              />
            </FormGroup>
          </>
        ) : (
          <WhisperSpinner size={24} backColor="#8b0304" frontColor="#fff" />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} primary loading={loading}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
