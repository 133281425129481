import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';

import { BiEnvelope, BiCodeAlt } from 'react-icons/bi';
import { FiAtSign } from 'react-icons/fi';

import { Container, Body } from './styles';
import { HeaderComponent } from '../../components/Header';
import { useDocumentTitle } from '../../hooks/documentTitle';

export const Home: React.FC<{ title?: string }> = ({ title }) => {
  const { setTitle } = useDocumentTitle();

  useEffect(() => {
    setTitle(title);
  }, [setTitle, title]);

  return (
    <Container>
      {console.log('Github working!')}
      <HeaderComponent />
      <Body>
        <section className="welcomeHeader">
          <h1>welcome to the letters system!</h1>
          <hr />
        </section>
        <section className="actions">
          <Link to="/letters">
            <div>
              <div className="imgBox">
                <BiEnvelope size={64} color="#8b0304" />
                <h1>LETTERS</h1>
              </div>
              <p>Import letter data and generate the PDF letters</p>
            </div>
          </Link>
          <Link to="/templates">
            <div>
              <div className="imgBox">
                <BiCodeAlt size={64} color="#8b0304" />
                <h1>TEMPLATES</h1>
              </div>
              <p>Build customized templates to generate the letters</p>
            </div>
          </Link>
          <Link to="/fields">
            <div>
              <div className="imgBox">
                <FiAtSign size={64} color="#8b0304" />
                <h1>FIELDS</h1>
              </div>
              <p>Craete new fields to be replaced at the templates</p>
            </div>
          </Link>
        </section>
      </Body>
    </Container>
  );
};
